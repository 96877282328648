import fetch from 'isomorphic-fetch'
import React, { useState, useEffect } from 'react'
import Client from 'shopify-buy/index.umd'
import Context from '~/context/StoreContext'
import { trackAddToCart } from '~/utils/tracking'

const isBrowser = typeof window !== 'undefined'

const client = Client.buildClient(
  {
    storefrontAccessToken: process.env.GATSBY_SHOPIFY_ACCESS_TOKEN,
    domain: `store.suitshop.com`,
  },
  fetch
)

export const ContextProvider = ({ children }) => {
  let initialStoreState = {
    client,
    fetching: true,
    adding: false,
    cartError: null,
    checkout: { lineItems: [] },
    products: [],
    shop: {},
    filteredType: 'all',
    filteredSort: 'featured',
    suitshopAccessToken: null,
    customerId: null,
    addVariantToCart: () => {},
    addBundleToCart: () => {},
    addVariantToCartAndBuyNow: () => {},
    removeLineItem: () => {},
    updateLineItem: () => {},
  }

  const [store, updateStore] = useState(initialStoreState)

  /**
   * Create a new checkout instance in Shopify
   *
   * @returns {Promise<Object>} The new checkout
   */
  const createNewCheckout = async () => {
    return store.client.checkout
      .create()
      .then(result => {
        setCheckoutInState(result)
        setTimeout(() => applyGorgiasAttributes(result.id), 500)
        return result
      })
      .catch(err => {
        console.log(err)

        updateStore(prevState => {
          return {
            ...prevState,
            adding: false,
            cartError:
              "There was an error creating your cart. Please try again later or call us at +1 (773) 303-6006. We're sorry for the inconvenience.",
          }
        })
      })
  }

  /**
   * Get the checkout ID
   * If there is no checkout ID, create a new one
   *
   * @returns {Promise<String>|String} The checkout ID
   */
  const getCheckoutId = async () => {
    const { checkout } = store
    let checkoutId = checkout.id

    if (typeof checkout.id === 'undefined') {
      const newCheckout = await createNewCheckout()
      checkoutId = newCheckout.id
    }

    return checkoutId
  }

  /**
   * Get the current custom attributes from the checkout
   * Map from GraphModel to regular object
   *
   * @returns {Array} The attributes
   */
  const getCurrentCustomAttributes = () => {
    return (
      store?.checkout?.customAttributes.map(attr => ({
        key: attr.key,
        value: attr.value,
      })) ?? []
    )
  }

  /**
   * Merge new custom attributes with the current ones
   * Remove any duplicates
   *
   * @param {Array} attributes Incoming, new attributes
   * @returns {Array} The merged attributes
   */
  const mergeCustomAttributes = attributes => {
    const currentAttributes = getCurrentCustomAttributes()
    const newAttributes = [...currentAttributes, ...(attributes ?? [])]

    const keys = newAttributes.map(({ key }) => key)
    return newAttributes.filter(
      ({ key }, index) => !keys.includes(key, index + 1)
    )
  }

  /**
   * Apply Gorgias attributes to the checkout
   * This is used to surface data for Gorgias Convert
   *
   * @param {*} checkoutId
   */
  const applyGorgiasAttributes = async checkoutId => {
    const initializeGorgias = window.GorgiasBridge
      ? window.GorgiasBridge.init()
      : new Promise(function (resolve, reject) {
          const timer = setTimeout(() => reject(), 500)
          window.addEventListener('gorgias-bridge-loaded', () => {
            clearTimeout(timer)
            resolve()
          })
        })

    initializeGorgias
      .then(async () => {
        const attributes = window.GorgiasBridge.createCheckoutAttributes() ?? []

        if (checkoutId && attributes.length > 0) {
          store.client.checkout
            .updateAttributes(checkoutId, {
              customAttributes: attributes,
            })
            .then(result => {
              if (result?.customAttributes) {
                updateStore(prevState => {
                  return {
                    ...prevState,
                    checkout: {
                      ...prevState.checkout,
                      customAttributes: attributes,
                    },
                  }
                })
              }
            })
        }
      })
      .catch(() => console.log('[GRG] Gorgias not found'))
  }

  /**
   * Set the checkout in the state
   *
   * @param {Object} checkout The checkout object
   * @returns {void}
   */
  const setCheckoutInState = checkout => {
    if (isBrowser) localStorage.setItem('shopify_checkout_id', checkout.id)
    updateStore(prevState => ({ ...prevState, checkout }))
  }

  /**
   * Initialize the checkout on entry
   * If there is a checkout ID in local storage, fetch the checkout
   * Otherwise, create a new checkout
   * If the checkout is completed or there is an error, remove the ID from storage
   *
   * @returns {void}
   */
  useEffect(() => {
    const initializeCheckout = async () => {
      const existingCheckoutID = isBrowser
        ? localStorage.getItem('shopify_checkout_id')
        : null

      if (existingCheckoutID && existingCheckoutID !== null) {
        store.client.checkout
          .fetch(existingCheckoutID)
          .then(checkout => {
            if (!checkout.completedAt) {
              setCheckoutInState(checkout)
            } else {
              localStorage.removeItem('shopify_checkout_id')
            }

            updateStore(prevState => {
              return { ...prevState, fetching: false }
            })
          })
          .catch(error => {
            console.log('Error fetching your cart. :(', error)
            localStorage.removeItem('shopify_checkout_id')

            updateStore(prevState => {
              return { ...prevState, fetching: false }
            })
          })
      } else {
        updateStore(prevState => {
          return { ...prevState, fetching: false }
        })
      }
    }

    initializeCheckout()
  }, [store.client.checkout])

  return (
    <Context.Provider
      value={{
        store,

        /**
         * Add bundle to cart (quantity always one)
         *
         * @param {Object} product
         * @param {Array<Object>} variants
         * @returns {void}
         */
        addBundleToCart: async (product, variants) => {
          if (
            variants.length === 0 ||
            typeof variants[0] === 'undefined' ||
            variants[0].shopifyId === ''
          ) {
            console.error('Add to cart error')
            return
          }

          updateStore(prevState => {
            return { ...prevState, adding: true }
          })

          const checkoutId = await getCheckoutId()

          if (typeof checkoutId !== 'undefined') {
            let lineItemsToUpdate = variants.map(variant => ({
              variantId: variant.shopifyId,
              quantity: parseInt(1, 10),
            }))

            trackAddToCart(product, variants, checkoutId)

            store.client.checkout
              .addLineItems(checkoutId, lineItemsToUpdate)
              .then(checkout => {
                updateStore(prevState => {
                  return { ...prevState, checkout, adding: false }
                })
              })
              .catch(error => {
                console.error(error)
                updateStore(prevState => {
                  return { ...prevState, adding: false }
                })
              })
          }
        },

        /**
         * Add customized variant to the cart
         *
         * @param {String} variantId
         * @param {Array<Object>} attributes
         * @returns {void}
         */
        addCustomizedVariantToCart: async (variantId, attributes) => {
          if (variantId === '') {
            console.error('A quantity is required.')
            return
          }

          updateStore(prevState => {
            return { ...prevState, adding: true }
          })

          const checkoutId = await getCheckoutId()

          if (typeof checkoutId !== 'undefined') {
            const lineItemsToUpdate = [
              {
                variantId: variantId,
                quantity: parseInt(1, 10),
                customAttributes: attributes,
              },
            ]

            store.client.checkout
              .addLineItems(checkoutId, lineItemsToUpdate)
              .then(checkout => {
                updateStore(prevState => {
                  return { ...prevState, checkout, adding: false }
                })
              })
          }
        },

        /**
         * Add a variant to the cart
         *
         * @param {Object} product    The product object
         * @param {Object} variant    The variant object
         * @param {Int} quantity      The quantity of the variant
         * @param {Boolean} silent    Whether to show the loading indicator
         * @returns {void}
         */
        addVariantToCart: async (
          product,
          variant,
          quantity,
          silent = false
        ) => {
          if (
            !variant ||
            typeof variant === 'undefined' ||
            variant.shopifyId === '' ||
            !quantity
          ) {
            console.error('Both a size and quantity are required.')
            return
          }

          if (!silent) {
            updateStore(prevState => {
              return { ...prevState, adding: true }
            })
          }

          const checkoutId = await getCheckoutId()

          if (typeof checkoutId !== 'undefined') {
            const lineItemsToUpdate = [
              {
                variantId: variant.shopifyId,
                quantity: parseInt(quantity, 10),
              },
            ]

            if (product && variant && checkoutId) {
              trackAddToCart(product, [variant], checkoutId)
            }

            return store.client.checkout
              .addLineItems(checkoutId, lineItemsToUpdate)
              .then(checkout => {
                updateStore(prevState => {
                  return { ...prevState, checkout, adding: false }
                })
              })
              .catch(error => {
                console.error(error)
                updateStore(prevState => {
                  return { ...prevState, adding: false }
                })
              })
          }
        },

        /**
         * Remove error message from cart
         *
         * @returns {void}
         */
        removeCartError: () => {
          updateStore(prevState => {
            return { ...prevState, cartError: null }
          })
        },

        /**
         * Remove the line item from the card
         *
         * @param {*} client
         * @param {String} checkoutID
         * @param {String} lineItemID
         * @param {boolean} silent
         * @returns {void}
         */
        removeLineItem: (client, checkoutID, lineItemID, silent = false) => {
          if (!silent) {
            updateStore(prevState => {
              return { ...prevState, adding: true }
            })
          }

          return client.checkout
            .removeLineItems(checkoutID, [lineItemID])
            .then(res => {
              updateStore(prevState => {
                return { ...prevState, checkout: res, adding: false }
              })
            })
            .catch(error => {
              updateStore(prevState => {
                return { ...prevState, adding: false }
              })
            })
        },

        /**
         * Remove stale line items from the cart
         *
         * @returns {void}
         */
        removeStaleLineItems: () => {
          try {
            const { client, checkout } = store

            if (checkout.lineItems.length > 0) {
              let lineItemsToRemove = checkout.lineItems.filter(
                item => item.variant === null
              )

              if (lineItemsToRemove.length > 0) {
                client.checkout
                  .removeLineItems(
                    checkout.id,
                    lineItemsToRemove.map(item => item.id)
                  )
                  .then(res => {
                    updateStore(prevState => {
                      return { ...prevState, checkout: res }
                    })
                  })
              }
            }
          } catch (error) {
            console.log('could not remove stale line items', error)
          }
        },

        /**
         * Update the cart's attributes
         *
         * @param {Object} client
         * @param {String} checkoutID
         * @param {Array<Object>} attributes
         * @returns {Promise<Object>|Object} The updated checkout
         */
        updateCartAttributes: async (checkoutID, attributes) => {
          const newAttributes = mergeCustomAttributes(attributes)

          return store.client.checkout
            .updateAttributes(checkoutID, {
              customAttributes: newAttributes,
            })
            .then(res => {
              updateStore(prevState => {
                return { ...prevState, checkout: res }
              })
              return res
            })
            .catch(error => {
              console.error('Error updating cart attributes', error)
              return store.checkout ?? {}
            })
        },

        /**
         * Update the quantity of a line item in the cart
         *
         * @param {*} client
         * @param {String} checkoutID
         * @param {String} lineItemID
         * @param {Int} quantity
         * @returns {void}
         */
        updateLineItem: (client, checkoutID, lineItemID, quantity) => {
          client.checkout
            .updateLineItems(checkoutID, [
              { id: lineItemID, quantity: parseInt(quantity, 10) },
            ])
            .then(res => {
              updateStore(prevState => {
                return { ...prevState, checkout: res }
              })
            })
        },
      }}
    >
      {children}
    </Context.Provider>
  )
}
