import React, { useState } from 'react'
import NavRightArrow from '~/images/svg/arrow-right-nav.svg'
import MobileMenuTitle from '~/components/Navigation/MobileMenu/MobileMenuTitle'

import { MobileList, MobileListItem, MobileLink } from '~/styles/navigation'

const MobileShopMenu = ({ active, isActive, setActive, routeChange }) => {
  const [activeTab, setActiveTab] = useState('Main')

  if (isActive) {
    return (
      <>
        {activeTab !== 'Main' ? (
          <>
            <MobileMenuTitle title={activeTab} setActive={setActiveTab} />
            <DynamicShopMenu
              activeTab={activeTab}
              setActiveTab={setActiveTab}
              routeChange={routeChange}
            />
          </>
        ) : (
          <>
            <MobileMenuTitle title={active} setActive={setActive} />
            <MobileList>
              <MobileListItem className="mb-1 text-base">
                <button
                  className="flex justify-between items-center py-3.5 w-full text-left"
                  onClick={() => setActiveTab('Occasions')}
                >
                  Occasions
                  <NavRightArrow />
                </button>
              </MobileListItem>
              <MobileListItem className="mb-1 text-base">
                <button
                  className="flex justify-between items-center py-3.5 w-full text-left"
                  onClick={() => setActiveTab('Suits & Tuxedos')}
                >
                  Suits &amp; Tuxedos
                  <NavRightArrow />
                </button>
              </MobileListItem>
              <MobileListItem className="mb-1 text-base">
                <button
                  className="flex justify-between items-center py-3.5 w-full text-left"
                  onClick={() => setActiveTab('Separates')}
                >
                  Separates
                  <NavRightArrow />
                </button>
              </MobileListItem>
              <MobileListItem className="mb-1 text-base">
                <button
                  className="flex justify-between items-center py-3.5 w-full text-left"
                  onClick={() => setActiveTab('Accessories')}
                >
                  Accessories
                  <NavRightArrow />
                </button>
              </MobileListItem>
              <MobileListItem className="mb-1 text-base">
                <button
                  className="flex justify-between items-center py-3.5 w-full text-left"
                  onClick={() => setActiveTab('Gifts & Extras')}
                >
                  Gifts & Extras
                  <NavRightArrow />
                </button>
              </MobileListItem>
              <MobileListItem className="mb-1 text-base">
                <MobileLink
                  to="/made-to-order/"
                  onClick={routeChange}
                  className="py-3.5 w-full"
                >
                  Made-to-Order Suiting
                </MobileLink>
              </MobileListItem>
              <MobileListItem className="mb-1 text-base">
                <MobileLink
                  to="/collections/sale/"
                  onClick={routeChange}
                  className="py-3.5 w-full"
                >
                  50% Off Sale
                </MobileLink>
              </MobileListItem>
            </MobileList>
          </>
        )}
      </>
    )
  }

  return null
}

const DynamicShopMenu = ({ activeTab, setActiveTab, routeChange }) => {
  const components = {
    Occasions: () => <OccasionsShopMenu routeChange={routeChange} />,
    'Suits & Tuxedos': () => <SuitsShopMenu routeChange={routeChange} />,
    Separates: () => <SeparatesShopMenu routeChange={routeChange} />,
    Accessories: () => <AccessoriesShopMenu routeChange={routeChange} />,
    'Gifts & Extras': () => <GiftsShopMenu routeChange={routeChange} />,
  }

  const Component = components[activeTab]

  return <>{Component ? <Component /> : null}</>
}

const OccasionsShopMenu = ({ routeChange }) => {
  return (
    <MobileList>
      <MobileListItem className="mb-0">
        <MobileLink to="/collections/mens/wedding-suits/" onClick={routeChange}>
          Men's Wedding Suits
        </MobileLink>
        <MobileLink
          to="/collections/mens/wedding-tuxedos/"
          onClick={routeChange}
        >
          Men's Wedding Tuxedos
        </MobileLink>
        <MobileLink
          to="/collections/womens/wedding-suits/"
          onClick={routeChange}
        >
          Women's Wedding Suits
        </MobileLink>
        <MobileLink
          to="/collections/womens/wedding-tuxedos/"
          onClick={routeChange}
        >
          Women's Wedding Tuxedos
        </MobileLink>
        <MobileLink
          to="/collections/all/prom-suits-tuxedos/"
          onClick={routeChange}
        >
          Prom & Homecoming
        </MobileLink>
      </MobileListItem>
    </MobileList>
  )
}

const SuitsShopMenu = ({ routeChange }) => {
  return (
    <MobileList>
      <MobileListItem className="mb-0">
        <MobileLink
          to="/collections/mens/suits-and-tuxedos/"
          onClick={routeChange}
        >
          Men's Suits & Tuxedos
        </MobileLink>
        <MobileLink to="/collections/mens/suits/" onClick={routeChange}>
          Men's Suits
        </MobileLink>
        <MobileLink to="/collections/mens/tuxedos/" onClick={routeChange}>
          Men's Tuxedos
        </MobileLink>
        <MobileLink
          to="/collections/womens/suits-and-tuxedos/"
          onClick={routeChange}
        >
          Women's Suits & Tuxedos
        </MobileLink>
        <MobileLink to="/collections/womens/suits/" onClick={routeChange}>
          Women's Suits
        </MobileLink>
        <MobileLink to="/collections/womens/tuxedos/" onClick={routeChange}>
          Women's Tuxedos
        </MobileLink>
        <MobileLink to="/collections/kids/" onClick={routeChange}>
          Kids & Toddlers
        </MobileLink>
      </MobileListItem>
    </MobileList>
  )
}

const SeparatesShopMenu = ({ routeChange }) => {
  return (
    <MobileList>
      <MobileListItem className="mb-0">
        <MobileLink to="/collections/mens-suit-jackets/" onClick={routeChange}>
          Men's Jackets
        </MobileLink>
      </MobileListItem>
      <MobileListItem>
        <MobileLink to="/collections/mens-dress-pants/" onClick={routeChange}>
          Men's Pants
        </MobileLink>
      </MobileListItem>
      <MobileListItem>
        <MobileLink to="/collections/womens-blazers/" onClick={routeChange}>
          Women's Jackets
        </MobileLink>
      </MobileListItem>
      <MobileListItem>
        <MobileLink to="/collections/womens-dress-pants/" onClick={routeChange}>
          Women's Pants
        </MobileLink>
      </MobileListItem>
      <MobileListItem>
        <MobileLink
          to="/collections/unisex-suit-jackets/"
          onClick={routeChange}
        >
          Unisex Jackets
        </MobileLink>
      </MobileListItem>
    </MobileList>
  )
}

const AccessoriesShopMenu = ({ routeChange }) => {
  return (
    <MobileList>
      <MobileListItem className="mb-0">
        <MobileLink to="/collections/ties/" onClick={routeChange}>
          Ties & Bowties
        </MobileLink>
      </MobileListItem>
      <MobileListItem>
        <MobileLink to="/collections/vests/" onClick={routeChange}>
          Suit Vests
        </MobileLink>
      </MobileListItem>
      <MobileListItem>
        <MobileLink to="/collections/shirts/" onClick={routeChange}>
          White Dress Shirts
        </MobileLink>
      </MobileListItem>
      <MobileListItem>
        <MobileLink to="/collections/shoes/" onClick={routeChange}>
          Dress Shoes
        </MobileLink>
      </MobileListItem>
      <MobileListItem>
        <MobileLink to="/collections/belts/" onClick={routeChange}>
          Leather Belts
        </MobileLink>
      </MobileListItem>
      <MobileListItem>
        <MobileLink to="/collections/suspenders/" onClick={routeChange}>
          Suspenders
        </MobileLink>
      </MobileListItem>
      <MobileListItem>
        <MobileLink to="/products/socks/" onClick={routeChange}>
          Socks
        </MobileLink>
      </MobileListItem>
      <MobileListItem>
        <MobileLink to="/collections/tie-bars/" onClick={routeChange}>
          Tie Bars
        </MobileLink>
      </MobileListItem>
      <MobileListItem>
        <MobileLink to="/collections/cufflinks/" onClick={routeChange}>
          Cufflinks
        </MobileLink>
      </MobileListItem>
      <MobileListItem>
        <MobileLink to="/collections/pocket-squares/" onClick={routeChange}>
          Pocket Squares
        </MobileLink>
      </MobileListItem>
      <MobileListItem>
        <MobileLink to="/collections/cummerbunds/" onClick={routeChange}>
          Cummerbunds
        </MobileLink>
      </MobileListItem>
    </MobileList>
  )
}

const GiftsShopMenu = ({ routeChange }) => {
  return (
    <MobileList>
      <MobileListItem className="mb-0">
        <MobileLink
          to="/products/halfday-garment-duffel/"
          onClick={routeChange}
        >
          Halfday Garment Bag
        </MobileLink>
      </MobileListItem>
      <MobileListItem>
        <MobileLink to="/collections/whiskey/" onClick={routeChange}>
          Whiskey
        </MobileLink>
      </MobileListItem>
      <MobileListItem>
        <MobileLink to="/collections/flasks/" onClick={routeChange}>
          Hand Flasks
        </MobileLink>
      </MobileListItem>
      <MobileListItem>
        <MobileLink to="/collections/watches/" onClick={routeChange}>
          Watches
        </MobileLink>
      </MobileListItem>
      <MobileListItem>
        <MobileLink to="/collections/wallets/" onClick={routeChange}>
          Leather Wallets
        </MobileLink>
      </MobileListItem>
    </MobileList>
  )
}

export default MobileShopMenu
