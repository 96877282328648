import { navigate } from 'gatsby'
var atob = require('atob');

export const arrayEquals = (a, b) => {
    return Array.isArray(a) &&
        Array.isArray(b) &&
        a.length === b.length &&
        a.every((val, index) => val === b[index]);
}

export const getSetPrice = (j, p) => {
    const combined = typeof p?.minVariantPrice !== 'undefined' ? parseFloat(j.minVariantPrice.amount) + parseFloat(p.minVariantPrice.amount) : parseFloat(j.minVariantPrice.amount);
    return combined.toString();
}

export const getVariantIdFromBase64 = (encodedId) => {
    try {
        const decoded = atob(encodedId);
        const shopifyId = decoded.split("/").pop()
        return parseInt(shopifyId)
    } catch (e) {
        //
    }

    return encodedId
}

export const getIdFromGraph = (graphId) => {
    return graphId.split("/").pop()
}

export const getPlainImageUrl = (url) => {
    if (typeof url !== 'string') return url;
    const index = url.indexOf('?v=')
    return index > -1 ? url.substring(0, index) : url;
}

export const getCheckoutIdFromBase64 = (encodedId) => {
    try {
        if (!encodedId.includes('://shopify')) encodedId = atob(encodedId);
        const stripped = encodedId.substring(0, encodedId.indexOf('?key='))
        return getIdFromGraph(stripped)
    } catch (e) {
        //
    }

    return encodedId
}

export const getQueryParameters = (query) => {
    return query !== '' ? JSON.parse('{"' + decodeURI(query).replace(/"/g, '\\"').replace(/&/g, '","').replace(/=/g, '":"') + '"}') : {}
}

export const updateVariantUrl = (decodedVariantId) => {
    try {
        if (decodedVariantId !== null && typeof decodedVariantId !== 'undefined') {
            const url = new URL(window.location)
            url.searchParams.set('variant', decodedVariantId)
            // navigate(url.search, { replace: true })
            navigate(url.search, {
                replace: true,
                state: {
                    disableScrollUpdate: true,
                },
            })
        }
    } catch (error) {
        console.log(error);
    }
}

export const getVariantTitle = (title) => {
    let indexOfHyphen = title.indexOf(' - Backordered')
    return indexOfHyphen > 0 ? title.substr(0, indexOfHyphen) : title
}

export const getBackorderDate = (title) => {
    const pieces = title.split(' - Backordered until ')
    if (typeof pieces[1] !== 'undefined') return pieces[1]
    return ''
}

export const priceFormat = (amount, currencyCode) => (
    Intl.NumberFormat(undefined, {
        currency: currencyCode,
        minimumFractionDigits: 2,
        style: 'currency',
    }).format(parseFloat(amount))
)


export const addWorkDays = (startDate, days) => {
    if (isNaN(days)) {
        console.log("Value provided for \"days\" was not a number");
        return;
    }
    if (!(startDate instanceof Date)) {
        console.log("Value provided for \"startDate\" was not a Date object");
        return;
    }
    var dow = startDate.getDay();
    var daysToAdd = parseInt(days);
    if (dow === 0)
        daysToAdd++;
    if (dow + daysToAdd >= 6) {
        var remainingWorkDays = daysToAdd - (5 - dow);
        daysToAdd += 2;
        if (remainingWorkDays > 5) {
            daysToAdd += 2 * Math.floor(remainingWorkDays / 5);
            if (remainingWorkDays % 5 === 0)
                daysToAdd -= 2;
        }
    }
    startDate.setDate(startDate.getDate() + daysToAdd);
    return startDate;
}


export const addDays = (date, days) => {
    var result = new Date(date);
    result.setDate(result.getDate() + days);
    return result;
}


export const parseDate = date => {
    let parts = date.substring(0, 10).split('-')
    return new Date(parts[0], parts[1] - 1, parts[2]);
}


// does not support duplicate values
export const moveValueToFront = (value, array) => {
    if (array.indexOf(value) !== -1) {
        const filteredArray = array.filter(i => i !== value);
        return [value, ...filteredArray];
    }

    return array;
}


export const formErrorOptions = (error) => {
    return {
        icon: 'warning',
        title: 'Error',
        html: formErrorHtml(error.data.errors),
        confirmButtonText: 'Okay, thanks.',
        confirmButtonColor: '#0E4581',
        showClass: {
            backdrop: 'swal2-noanimation',
            popup: ''
        }
    }
}


export const serverErrorOptions = (error) => {
    return {
        icon: 'error',
        title: 'Error',
        text: 'We could not process your request.',
        confirmButtonText: 'Okay, thanks.',
        confirmButtonColor: '#0E4581',
        showClass: {
            backdrop: 'swal2-noanimation',
            popup: ''
        }
    }
}


export const formErrorHtml = (errors) => {
    let errorsHtml = '<ul>';
    let flattenedErrors = typeof errors === 'object' ? Object.values(errors).flat() : errors

    flattenedErrors.forEach(e => errorsHtml += '<li>' + e + '</li>')
    errorsHtml += '</ul>'

    return errorsHtml;
}


export const titleCase = (str) => {
    return str.toLowerCase().split(' ').map(function (word) {
        return (word.charAt(0).toUpperCase() + word.slice(1));
    }).join(' ');
}