import React from 'react'

const StoreContext = React.createContext({
    store: {
        fetching: true,
        adding: false,
        checkout: { lineItems: [] },
        products: [],
        shop: {},
        filteredType: 'all',
        filteredSort: 'featured',
        suitshopAccessToken: null,
        customerId: null,
        addVariantToCart: () => { },
        addBundleToCart: () => { },
        addVariantToCartAndBuyNow: () => { },
        removeLineItem: () => { },
        updateLineItem: () => { },
    },
    fitRecommendations: {
        men: {
            jacket: null,
            pant: null
        },
        women: {
            jacket: null,
            pant: null
        }
    }
})

export default StoreContext
