import React from 'react'
import { Link } from 'gatsby'
import HelpIcon from '~/images/svg/white-help-icon.svg'
import DesktopSearchBar from './MainNavigation/DesktopSearchBar'

import { PaddedContainer, Content } from '~/styles/base'

const TopNavigationBar = () => (
  <div className="bg-rich-black h-[44px] w-full text-white text-sm fixed top-0 z-100">
    <PaddedContainer className="h-[44px]">
      <Content className="flex items-center justify-between h-full">
        <LeftTopNav />
        <RightTopNav />
      </Content>
    </PaddedContainer>
  </div>
)

const LeftTopNav = () => {
  return (
    <Link
      to={`/made-to-order/`}
      className="font-medium text-center w-full md:w-auto md:text-left md:hover:underline"
    >
      Wear it your way with Made-to-Order.
    </Link>
  )
}

const RightTopNav = () => {
  return (
    <div className="hidden md:flex items-center h-full">
      <div className="pr-4 hidden xl:block">
        <DesktopSearchBar />
      </div>
      <div className="px-4 xl:border-l xl:border-white">
        <NavStoreLocation />
      </div>
      <div className="pl-4 border-l border-white">
        <NavHelpLink />
      </div>
    </div>
  )
}

const NavStoreLocation = () => {
  return (
    <Link to={`/store-locations/`} className="font-medium hover:underline">
      Locations
    </Link>
  )
}

const NavHelpLink = () => {
  return (
    <a
      href="https://suitshop.gorgias.help/en-US"
      target="_blank"
      rel="noopener noreferrer"
      className="font-medium hover:underline"
    >
      <HelpIcon className="inline-block relative bottom-[1px] mr-2.5" />
      Help
    </a>
  )
}

export default TopNavigationBar
