import React, { useState, useEffect } from 'react'
import { globalHistory } from '@reach/router'

// Mobile Menu
import MobileMenu from '~/components/Navigation/MobileMenu'

// Main Dropdown Menu Items
import NavLogo from '~/components/Navigation/MainNavigation/NavLogo'
import NavDropdown from '~/components/Navigation/MainNavigation/NavDropdown'
import NavLink from '~/components/Navigation/MainNavigation/NavLink'

// Mega Menus
import DynamicMegaMenu from '~/components/Navigation/MegaMenu/DynamicMegaMenu'

// Secondary Navigation Sections
import Account from '~/components/Navigation/Account'
import Cart from '~/components/Navigation/Cart'
import GroupButton from '~/components/Navigation/GroupButton'
import Hamburger from '~/components/Navigation/Hamburger'
import NavClose from '~/components/Navigation/NavClose'

import {
    handleToggle
} from '~/utils/navigation'

import {
    NavContainer as Container,
    NavContent as Content,
    SecondaryMenuContainer,
    NavList,
    NavListItem
} from '~/styles/navigation'


const MainNavigation = ({ open, setOpen }) => {
    const [active, setActive] = useState('Main');

    useEffect(() => {
        document.getElementById("mobileDrawer").scroll({ top: 0 })
    }, [active, open])

    return (
        <>
            <div className="bg-white h-[60px] w-full text-rich-black text-sm fixed top-[44px] z-100">
                <Container>
                    <Content>
                        <NavLogo />
                        <DesktopMenu />

                        <SecondaryDesktopMenu
                            open={open}
                            toggleMenu={setOpen}
                            setActive={setActive}
                        />
                    </Content>
                </Container>
            </div>

            <MobileMenu
                open={open}
                toggleMenu={setOpen}
                active={active}
                setActive={setActive}
            />
        </>
    )
}


const DesktopMenu = () => {
    const [active, setActive] = useState(false);

    useEffect(() => {
        return globalHistory.listen(({ action }) => {
            if (action === 'PUSH') handleToggle(setActive, false)
        })
    }, [active])

    return (
        <div className="hidden xl:block flex-1 flex justify-center h-full" onMouseLeave={() => handleToggle(setActive, false)}>
            <NavList>
                <NavListItem>
                    <NavDropdown title="Shop" active={active} callback={setActive}>
                        <DynamicMegaMenu menuName="Shop" active={active} />
                    </NavDropdown>
                </NavListItem>
                <NavListItem>
                    <NavDropdown title="Get Started" active={active} callback={setActive}>
                        <DynamicMegaMenu menuName="GetStarted" active={active} />
                    </NavDropdown>
                </NavListItem>
                <NavListItem>
                    <NavDropdown title="Weddings & Events" active={active} callback={setActive}>
                        <DynamicMegaMenu menuName="Weddings&Events" active={active} />
                    </NavDropdown>
                </NavListItem>
                <NavListItem>
                    <NavLink title={`Fit Guide`} active={active} callback={setActive} to={`/fit-guide/`} />
                </NavListItem>
                <NavListItem>
                    <NavDropdown title="Resources" active={active} callback={setActive}>
                        <DynamicMegaMenu menuName="Resources" active={active} />
                    </NavDropdown>
                </NavListItem>
            </NavList>
        </div>
    )
}

const SecondaryDesktopMenu = ({ open, toggleMenu, setActive }) => {

    const handleToggle = (menuState) => {
        toggleMenu(menuState)
        if (!menuState) setTimeout(setActive('Main'), 500)
    }

    return (
        <SecondaryMenuContainer>

            <GroupButton />
            <Account />
            <Cart />

            {open ?
                <NavClose onClick={() => handleToggle(false)} /> :
                <Hamburger onClick={() => handleToggle(true)} />}

        </SecondaryMenuContainer>
    )
}

export default MainNavigation